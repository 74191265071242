// src/components/ProductList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProductList({ onAddToCart, quantities }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleQuantityChange = (productId, change) => {
    const newQuantity = Math.max((quantities[productId] || 0) + change, 0);
    onAddToCart(products.find(product => product._id === productId), newQuantity);
  };

  const categories = {
    "Breakfast": "Breakfast",
    "Lunch and Dinner Essentials": "Meals",
    "Fruit and Vegetables": "Fruits",
    "Snacks": "Snacks",
    "Drinks": "Drinks",
    "Miscellaneous": "Miscellaneous"
  };

  const groupedProducts = Object.entries(categories).reduce((acc, [key, value]) => {
    acc[key] = products.filter(product => product.category === value);
    return acc;
  }, {});

  return (
    <div className="container">
      <div className="headline-container text-center my-4">
        <h1 className="display-4">Purchase Your Groceries in Advance of Your Vacations</h1>
        <p className="lead">Get all your essentials delivered to your vacation spot before you arrive!</p>
      </div>
      <h2>Products</h2>
      {Object.entries(groupedProducts).map(([category, products]) => (
        <div key={category}>
          <h3>{category}</h3>
          <div className="row">
            {products.map(product => (
              /*<div className="col-6 col-md-3 mb-4" key={product._id}>*/
              <div className="col-6 col-md-3 col-sm-6 mb-4" key={product._id}>
                <div className="card h-100">
                  <img src={`${process.env.REACT_APP_API_URL}${product.image}`} className="card-img-top" alt={product.name} />
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title" style={{ fontSize: '1rem', marginBottom: '10px' }}>{product.name}</h5>
                    <p className="card-text">€{product.price.toFixed(2)}</p>
                    <div className="mt-auto">
                      <div className="quantity-selector d-flex justify-content-between align-items-center">
                        <button className="btn btn-outline-secondary" onClick={() => handleQuantityChange(product._id, -1)}>-</button>
                        <span>{quantities[product._id] || 0}</span>
                        <button className="btn btn-outline-secondary" onClick={() => handleQuantityChange(product._id, 1)}>+</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
